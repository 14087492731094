D.addEventListener("DOMContentLoaded", function() {

  fetch('https://biznesfunding.click/static/js/companies.json')
    .then((res) => res.json())
    .then((data) => console.log(data))


  // fetch('https://mdn.github.io/learning-area/javascript/oojs/json/superheroes.json')
	// .then((res) => res.json())
	// .then((data) => setHeroes(data))

});